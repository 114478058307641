import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export const MenuListItem = ({
  path,
  label,
  icon,
  onClick,
  match,
  role,
  roles,
  matchPath,
}) =>
  roles.includes(role) && (
    <ListItem button component={path && Link} to={path} onClick={onClick}>
      <ListItemIcon>
        <Icon className="icon-right material-icons">{icon}</Icon>
      </ListItemIcon>
      <ListItemText inset primary={label} />
    </ListItem>
  );
