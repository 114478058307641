import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

const ButtonWithLoading = (props) => {
	const {
		label,
		disabled,
		loading,
		success,
		error,
		variant,
		color,
		type,
		style,
		progressStyle,
		onClick,
		defaultIcon,
	} = props;
  return (
  	<Button style={style} onClick={onClick} variant={variant} color={color} disabled={disabled} type={type}>
      {label}&nbsp;
      {loading && <CircularProgress style={progressStyle} size={20} thickness={3} />}
      {error && <Icon>warning</Icon>}
      {success && <Icon>done</Icon>}
      {!loading && !error && !success && <Icon>{defaultIcon}</Icon>}
  	</Button>
  )
}
ButtonWithLoading.propTypes = {
	label: PropTypes.string,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.bool,
	variant: PropTypes.string,
	color: PropTypes.string,
	type: PropTypes.string,
	style: PropTypes.object,
	progressStyle: PropTypes.object,
	onClick: PropTypes.func,
	defaultIcon: PropTypes.string,
}
ButtonWithLoading.defaultProps = {
	progressStyle: {color: '#ffffff'},
	defaultIcon: 'keyboard_arrow_right',
}
export default ButtonWithLoading;