import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
// import AnimatedComponent from './AnimatedComponent';

const InfoBox = (props) => {
  return (
    // this broke something post-ssr
    // <AnimatedComponent key={props.key}>
    <Paper
      style={{ backgroundColor: props.backgroundColor, padding: 16 }}
      elevation={props.elevation}
    >
      <Typography
        variant="body1"
        style={{ alignItems: 'center', display: 'flex' }}
        color={props.type === 'success' ? 'primary' : props.type}
      >
        {props.type && props.type === 'error' && (
          <Icon style={styles.icon}>warning</Icon>
        )}
        {props.type && props.type === 'success' && (
          <Icon style={styles.icon}>check</Icon>
        )}
        {props.children}
      </Typography>
    </Paper>
    // </AnimatedComponent>
  );
};
InfoBox.defaultProps = {
  elevation: 0,
  type: 'default',
  backgroundColor: 'white',
};
InfoBox.propTypes = {
  type: PropTypes.string,
};

const styles = {
  icon: {
    marginRight: 8,
  },
};
export default InfoBox;
