import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import rootReducer from '../reducers';
const createBrowserHistory = require('history').createBrowserHistory;
const createMemoryHistory = require('history').createMemoryHistory;

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const isClient = typeof window !== 'undefined';
export const history =
  isClient && window.document ? createBrowserHistory() : createMemoryHistory();

// isClient &&
//   history.listen((location) => {
//     console.log('TRACKING PAGE VIEW: ', location.pathname + location.search);
//     window.ga('set', 'page', location.pathname + location.search);
//     window.ga('send', 'pageview');
//   });
//console.log('process', process.env.BROWSER);
const historyMiddleware = routerMiddleware(history);

//import { autoRehydrate } from 'redux-persist'

const composeEnhancers =
  (typeof window != 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistConfig = {
  key: 'root',
  storage: storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = (initialState) => {
  let store = createStore(
    combineReducers({
      root: persistedReducer,
      router: routerReducer,
    }),
    initialState,
    composeEnhancers(
      applyMiddleware(historyMiddleware, thunkMiddleware)
      //  autoRehydrate(),
    )
    // window.devTools ? window.devTools({
    //   name: 'AJNpdfs', realtime: true
    // }) : f => f,
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
