import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Switch, Route, withRouter } from 'react-router';

import { connect } from 'react-redux';
import { dashboardSnack } from './actions/dashboard';
import Container from './components/Container';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import NoMatch from './views/NoMatch';
// import Loading from './components/Loading';
import Navbar from './components/Navbar';
// import AnimatedComponent from './components/AnimatedComponent';
// import WithAnimation from './components/withAnimation';
import * as Routes from './Routes';

const styles = (theme) => ({
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      showSnack: false,
    };
  }

  componentDidMount() {
    console.log('islogged?', this.props.isLogged);
    this.props.dashboardSnack(null);
    // remove ssr css
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.snack && this.props.snack !== nextProps.snack) {
      console.log('new snack');

      this.setState({ showSnack: true });
    }
  };

  handleCloseSnack = (event, reason) => {
    console.log('close snack');

    this.setState({ showSnack: false });
    this.props.dashboardSnack(null);
  };
  handleToggleMenu(e) {
    e.preventDefault();

    this.setState({
      toggleMenu: !this.state.toggleMenu,
    });
  }
  handleDrawerToggle = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  render() {
    const { classes } = this.props;
    const { store, match, location, history } = this.props;
    const { isLogged } = this.props;

    // console.log(match, location);
    // const isPdfView = location.pathname.split('/')[1] === 'view';

    return (
      <div className={classes.appFrame}>
        <Route
          path="/"
          render={({ location }) => {
            if (
              typeof window !== 'undefined' &&
              typeof window.ga === 'function'
            ) {
              console.log('PAGE VIEW: ', location.pathname + location.search);
              window.ga('set', 'page', location.pathname + location.search);
              window.ga('send', 'pageview');
            }
            return null;
          }}
        />
        <Switch>
          {/*isPdfView ? null : <Navbar/>*/}

          <Route
            exact
            path="/"
            render={(matchProps) => (
              <React.Fragment>
                <Navbar />
                <Container>
                  <Routes.Home isLogged={isLogged} />
                </Container>
              </React.Fragment>
            )}
          />
          <Route
            path="/login"
            exact
            //component={Routes.Login}
            render={(matchProps) => (
              <React.Fragment>
                <Navbar />
                <Container>
                  <Routes.Login />
                </Container>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/reset"
            // component={Routes.ResetPass}
            render={(matchProps) => (
              <React.Fragment>
                <Navbar />
                <Container>
                  <Routes.ResetPass {...matchProps} />
                </Container>
              </React.Fragment>
            )}
          />

          <Route
            exact
            path="/dashboard"
            // component={Routes.Dashboard}
            render={(matchProps) => (
              <React.Fragment>
                <Navbar />
                <Container>
                  <Routes.Dashboard {...matchProps} />
                </Container>
              </React.Fragment>
            )}
          />

          <Route
            exact
            path="/account"
            // component={Routes.Account}
            render={(matchProps) => (
              <React.Fragment>
                <Navbar />
                <Container>
                  <Routes.Account {...matchProps} />
                </Container>
              </React.Fragment>
            )}
          />
          <Route
            path="/edit/:id"
            // component={Routes.EditItem}
            render={(matchProps) => (
              <React.Fragment>
                <Navbar />
                <Container>
                  <Routes.Edit {...matchProps} />
                </Container>
              </React.Fragment>
            )}
          />

          <Route
            path="/view/:type/:slug"
            // component={Routes.PdfView}
            render={(matchProps) => (
              <React.Fragment>
                <Container noMargin>
                  <Routes.PdfView {...matchProps} />
                </Container>
              </React.Fragment>
            )}
          />

          <Route
            exact
            path="/pdfs/:type/:publication/:edition/:sort?"
            // component={Routes.List}
            render={(matchProps) => (
              <React.Fragment>
                <Navbar />
                <Container>
                  <Routes.List {...matchProps} />
                </Container>
              </React.Fragment>
            )}
          />

          <Route
            render={(matchProps) => (
              <React.Fragment>
                <Navbar />
                <Container>
                  <NoMatch {...matchProps} />
                </Container>
              </React.Fragment>
            )}
          />
        </Switch>

        <Snackbar
          key={this.props.snack}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.showSnack}
          autoHideDuration={6000}
          onClose={this.handleCloseSnack}
          ContentProps={{
            'aria-describedby': 'snack-message',
          }}
          message={<span id="snack-message">{this.props.snack}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnack}
            >
              <Icon>close</Icon>
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

App.propTypes = {
  initialized: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  initialized: state.root.auth.initialized,
  isLogged: state.root.auth.isLogged,
  snack: state.root.dashboardSnack,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dashboardSnack: (text) => dispatch(dashboardSnack(text)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withRouter(App)));
