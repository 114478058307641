import jsCookie from 'js-cookie';

import decode from 'jwt-decode';
import { pushRoute } from './router';

import { userIsAdminSuccess } from './user';
import setupApollo from '../apolloClient';

export function authInitializedDone() {
  return {
    type: 'AUTH_INITIALIZATION_DONE',
  };
}

export function authLoggedInSuccess(userUID) {
  return {
    type: 'AUTH_LOGGED_IN_SUCCESS',
    userUID,
  };
}

export function loginError(message) {
  return {
    type: 'LOGIN_ERROR',
    message,
  };
}

export function authLoggedOutSuccess() {
  return {
    type: 'AUTH_LOGGED_OUT_SUCCESS',
  };
}

export function setJwToken(jwToken) {
  return {
    type: 'AUTH_SET_TOKEN',
    jwToken,
  };
}
export function setRefreshToken(refreshToken) {
  return {
    type: 'AUTH_SET_REFRESH_TOKEN',
    refreshToken,
  };
}
// export function authInitialized(user) {
//   return (dispatch) => {
//     dispatch(authInitializedDone());
//     if (user) {
//       dispatch(authLoggedIn(user.uid));
//     } else {
//       dispatch(authLoggedOutSuccess());
//     }
//   };
// }

const isClient = typeof window !== 'undefined';

const getCookie = (name, token) =>
  new Promise((resolve, reject) => {
    console.log(token);

    if (isClient) {
      resolve(jsCookie.get(name));
    } else {
      // idk I gave up trying to get the cookie for SSR
      resolve(token);
      // console.log('fetching /api/getcookie on server');
      // return fetch(`/api/getcookie`, {
      //   'credentials': 'include',
      //   'headers': {},
      //   'referrerPolicy': 'no-referrer-when-downgrade',
      //   'body': null,
      //   'method': 'GET',
      //   'mode': 'cors'
      // })
      //   .then(data => data.json())
      //   .then((data) => {
      //     console.log('cookie res', data);
      //     resolve(data.token);
      //   })
      //   .catch(err => reject());
    }
  });

const setCookie = (name, value, expiry = 365, secure = false, domain = '') => {
  console.log('set cookie');

  // fetch(`${process.env.APP_DOMAIN}/api/setcookie`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'credentials': 'include',
  //   },
  //   body: JSON.stringify({
  //     name,
  //     value,
  //   })
  // })
  jsCookie.set(name, value, {
    expires: expiry,
    path: '/',
    secure,
    domain,
  });
};
const removeCookie = (name) => jsCookie.remove(name);

export const signIn = (id, tokenResults) => (dispatch) => {
  console.log(id, tokenResults);
  if (!id || !tokenResults.jwtToken) {
    dispatch(
      loginError(
        "Couldn't sign in. Please check your login details are correct."
      )
    );
    console.error('jwt or id undefined');
    throw TypeError(
      "Couldn't sign in. Please check your login details are correct."
    );
  }
  const { jwtToken, refreshToken } = tokenResults;
  const expiration = getTokenExpirationDate(jwtToken);
  console.log(expiration);

  setCookie('token', jwtToken, expiration, false);

  dispatch(setJwToken(jwtToken));
  dispatch(setRefreshToken(refreshToken));
  dispatch(authLoggedInSuccess(id));
};

export function logout() {
  return async (dispatch) => {
    console.log('logging out');
    dispatch(setJwToken(null));
    dispatch(setRefreshToken(null));
    removeCookie('token');
    const { client } = await setupApollo();
    isClient && client.resetStore();
    dispatch(authLoggedOutSuccess());
    // dispatch(pushRoute('/'));
  };
}
export function getToken() {
  getCookie('token')
    .then((jwToken) => {
      if (!!jwToken && !isTokenExpired(jwToken)) {
        return jwToken;
      }
      return null;
    })
    .catch(() => null);
}

export function loggedIn() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      // console.log('[loggedIn] token (redux)', getState().root.auth.jwToken);

      // Checks if there is a saved token and it's still valid
      getCookie('token', getState().root.auth.jwToken)
        .then((jwToken) => {
          if (!!jwToken && !isTokenExpired(jwToken)) {
            console.log('token exists and not yet expired');

            resolve();
          } else if (getState().root.auth.refreshToken) {
            // jwToken && ??? cookie is being removed as it's expired. so we can't refresh the token :/
            console.log('token exists but it has expired');
            // here is where I should check for refresh token and generate a new access token with it.
            refreshToken(
              getState().root.auth.currentUserUID,
              getState().root.auth.refreshToken
            )
              .then((res) => {
                console.log(res);
                if (res.success) {
                  const expiration = getTokenExpirationDate(res.token);
                  console.log('sucess', expiration);

                  setCookie('token', res.token, expiration, false);
                  dispatch(setJwToken(res.token));
                  resolve('refreshed');
                } else {
                  isClient && dispatch(logout());
                  reject('failed to refresh token.');
                }
              })
              .catch((error) => {
                console.log('refresh error', error);
                isClient && dispatch(logout());
                reject('failed to refresh token.');
              });
          } else {
            isClient && dispatch(logout());
          }
        })
        .catch(() => reject());
    });
}

function refreshToken(personId, refreshToken) {
  return new Promise((resolve, reject) =>
    fetch('/api/token', {
      method: 'POST',
      headers: {
        // 'authorization': `bearer ${jwToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        personId,
        refreshToken,
      }),
    })
      .catch((error) => {
        console.log(error);
        reject(error);
      })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          console.log(res.error);
          reject(res.error);
        } else {
          console.log('res', res);
          resolve(res);
        }
      })
  );
}
export function isTokenExpired(token) {
  console.log('token being checked', token);

  const date = getTokenExpirationDate(token);
  console.log('token exp:', date);
  const offsetSeconds = 0;
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

export function getTokenExpirationDate(token) {
  const decoded = decode(token);
  if (!decoded.exp) {
    return null;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
}

// export function getToken() {
//   return (getState) => {
//     // Retrieves the user token from the redux store
//     console.log(getState().root.auth.jwToken);
//     return getState().root.auth.jwToken;
//   };
// }

function redirect(replace, pathname, nextPathName, error = false) {
  replace({
    pathname,
    state: { nextPathname: nextPathName },
  });
  if (error) {
    console.error(error);
  }
}

export function requireAdmin(callback) {
  return (dispatch, getState) => {
    console.log(getState().root.auth.currentUserUID);
    if (getState().root.auth.isLogged) {
      switch (getState().root.user.isAdmin) {
        case false:
          dispatch(pushRoute('/'));
          break;
        case undefined:
          // no admin role in psql yet...
          dispatch(userIsAdminSuccess());
          // FirebaseApi.GetChildAddedByKeyOnce('/isAdmin/', getState().root.auth.currentUserUID)
          //   .then(
          //     user => {
          //       if (user.exists() && user.val()) {
          //         dispatch(userIsAdminSuccess());
          //         callback('is admin');
          //       } else {
          //         dispatch(pushRoute('/'));
          //       }
          //     })
          //   .catch(
          //     error => {
          //     //  dispatch(ajaxCallError());
          //     dispatch(pushRoute('/'));
          //       callback('error');
          //       // @TODO better error handling
          //       throw(error);
          //     });
          break;
        case true:
          callback('is already admin');
          break;
      }
    } else {
      dispatch(pushRoute('/'));
      callback("isn't logged in");
    }
  };
}
