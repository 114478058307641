import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Header, Content } from '../components';

const NoMatch = ({ location }) => (
    <div>
      <Header title="Oops" description="Couldn't find what you were looking for." />
      <Content>
      	<Typography>No match for <code>{location.pathname}</code></Typography>
    	</Content>
  </div>
);

export default NoMatch;