import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';

import { loadableReady } from '@loadable/component';

import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
//import blue from '@material-ui/core/colors/blue';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import CssBaseline from '@material-ui/core/CssBaseline';

//actions
import { getToken } from './actions/auth';
//component
import App from './App';
//store
import initialState from './reducers/initialState';
import { configureStore, history } from './store/configureStore';
import theme from './config/theme';
import { Route, BrowserRouter } from 'react-router-dom';

import { PersistGate } from 'redux-persist/lib/integration/react';
import setupApollo from './apolloClient';

// import '../css/style.css';
const headingFont =
  '"Archivo Black",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif';
const bodyFont = '"Muli","Helvetica Neue",Helvetica,Arial,sans-serif';

const primary = '#263238'; // #F44336
const { store, persistor } = configureStore({ root: initialState });

// preload(__INITIAL_MODULES__)
//   .then(() => {
//     ReactDOM.render((
//       // <MuiThemeProvider muiTheme={muiTheme}>
//         <ServerStateProvider state={__INITIAL_STATE__}>
//               <ApolloProvider client={client}>
//                 <Provider store={store}>
//                   <PersistGate persistor={persistor}>
//                     <ConnectedRouter history={history}>
//                       <Route component={App} />
//                     </ConnectedRouter>
//                   </PersistGate>
//                 </Provider>
//               </ApolloProvider>
//         </ServerStateProvider>
//       // </MuiThemeProvider>
//     ), document.getElementById('root'));

// apolloPersistor.restore()
// persistCache({
//   cache: apolloCache,
//   debug: true,
//   storage: window.localStorage,
// })
// .then(() =>

loadableReady(async () => {
  const { client } = await setupApollo();
  hydrate(
    <React.Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <BrowserRouter>
                <Route component={App} />
              </BrowserRouter>
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </MuiThemeProvider>
    </React.Fragment>,
    document.getElementById('root')
  );
});
// )
// .catch(error => console.error('Error restoring Apollo cache', error))
