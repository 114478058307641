import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Content from './Content';

const Loading = ({ size, thickness, inline, center, color, style }) => (
  <Tupperware style={style} inline={inline} center={center}>
    <CircularProgress size={size} thickness={thickness} color={color} />
  </Tupperware>
);

const Tupperware = ({ children, inline, center, style }) => {
  return inline ? (
    <div style={{ position: 'relative' }}>
      <span style={center ? { ...styles.center, ...style } : style}>
        {children}
      </span>
    </div>
  ) : (
    <Content style={styles.loading}>{children}</Content>
  );
};

const styles = {
  loading: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  center: {
    justifyContent: 'center',
    display: 'flex',
  },
};

Loading.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
  inline: PropTypes.bool,
  center: PropTypes.bool,
  color: PropTypes.string,
};
Loading.defaultProps = {
  size: 70,
  thickness: 4,
  inline: false,
  color: 'primary',
  center: false,
};
export default Loading;
