import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles/';
import classNames from 'classnames';

const styles = {
  paper: {
    backgroundColor: 'white',
    width: '100%',
  },
  paperDark: {
    backgroundColor: '#161619',
    width: '100%',
  }
}

class AlertDialog extends Component {
  state = {
    open: true,
  };
  componentWillReceiveProps(props, nextProps) {
    if (this.props.open !== nextProps.open) {
     this.setState({open: Boolean(nextProps.open)})
    }
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleConfirm = () => {
    this.setState({ open: false });
    this.props.onConfirm && this.props.onConfirm();
  };
  handleCancel = () => {
    this.setState({ open: false });
    this.props.onCancel && this.props.onCancel();
  };
  
  render() {
    const { 
      title,
      message,
      buttonText,
      canCancel,
      fullScreen,
      fullWidth,
      contentContainerStyle,
      maxWidth,
      dark,
      classes,
    } = this.props;
    console.log(this.state);
    
    return (
      <div>
        <Dialog
          classes={dark ? { paper: classes.paperDark } : { paper: classes.paper } }
          fullScreen={fullScreen} 
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          disableEnforceFocus
          open={this.state.open}
          onClose={this.handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
         { title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle> }
          <DialogContent style={contentContainerStyle}>
            {typeof message === 'string' ?
              <DialogContentText id="alert-dialog-description">
                {message}
              </DialogContentText>
              :
              message
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleConfirm} color="primary" autoFocus>
              {buttonText}
            </Button>
            {canCancel && 
              <Button onClick={this.handleCancel}>
               Cancel
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  title:PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.object
  ]).isRequired,
  buttonText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  canCancel: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  dark: PropTypes.bool,
  contentContainerStyle: PropTypes.object,
}

AlertDialog.defaultProps = {
  canCancel: false,
  buttonText: 'Ok',
  fullScreen: false,
}

export default withStyles(styles)(AlertDialog);
