export function dashboardUploadHasErrored(state = [{ error: false }], action) {
  switch (action.type) {
    case 'DASHBOARD_UPLOAD_HAS_ERRORED':
      return action.hasErrored;
    default:
      return state;
  }
}
export function uploadedId(state = null, action) {
  switch (action.type) {
    case 'SET_UPLOADED_ID':
      return action.uploadedId;
    default:
      return state;
  }
}

export function formIsSubmitting(state = false, action) {
  switch (action.type) {
    case 'FORM_IS_SUBMITTING':
      return action.isSubmitting;
    default:
      return state;
  }
}
export function uploadProgress(state = null, action) {
  switch (action.type) {
    case 'UPLOAD_PROGRESS':
      return action.uploadProgress;
    default:
      return state;
  }
}
export function dashboardSnack(state = null, action) {
  switch (action.type) {
    case 'DASHBOARD_SNACK':
      return action.dashboardSnack;
    default:
      return state;
  }
}
export function newItem(state = [], action) {
  switch (action.type) {
    case 'DASHBOARD_SUBMIT_UPLOAD_SUCCESS':
      return action.newItem;
    default:
      return state;
  }
}
export function clearUploadForm(state = null, action) {
  switch (action.type) {
    case 'CLEAR_UPLOAD_FORM':
      return action.clearUploadForm;
    default:
      return state;
  }
}
