export default {
  routesPermissions: {
    requireAuth: [
      '/admin'
    ],
    routesRequireAdmin: [
      '/admin'
    ]
  },
  routing: {},
  user: {
    isAdmin: false
  },
  dashboardSnack: null,
  auth: {
    refreshToken: null,
    jwToken: null,
    isAuthenticated: false,
    isLogged: false,
    currentUserUID: null,
    initialized: false,
    loginErrorMessage: null,
  },
};
