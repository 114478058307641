export function itemsHasErrored(state = false, action) {
    switch (action.type) {
        case 'ITEMS_HAS_ERRORED':
            return action.hasErrored;
        default:
            return state;
    }
}
export function itemsIsLoading(state = false, action) {
    switch (action.type) {
        case 'ITEMS_IS_LOADING':
            return action.isLoading;
        default:
            return state;
    }
}
export function itemsCopiedLink(state = false, action) {
    switch (action.type) {
        case 'ITEMS_COPIED_LINK':
            return action.copiedLink;
        default:
            return state;
    }
}
export function itemsSortBy(state = 'DATE_PUBLISHED_DESC', action) {
    switch (action.type) {
        case 'ITEMS_SORT_BY':
            return action.sortBy;
        default:
            return state;
    }
}
export function itemsFilterBy(state = {}, action) {
    switch (action.type) {
        case 'ITEMS_FILTER_BY':
            return action.filterBy;
        default:
            return state;
    }
}
export function itemsToggleView(state = true, action) {
    switch (action.type) {
        case 'ITEMS_TOGGLE_VIEW':
            return action.toggleView;
        default:
            return state;
    }
}
export function items(state = [], action) {
    switch (action.type) {
        case 'ITEMS_GET_DATA_SUCCESS':
            return action.items;
        default:
            return state;
    }
}
