export function dashboardUploadHasErrored(bool, error) {
  return { type: 'DASHBOARD_UPLOAD_HAS_ERRORED', hasErrored: { error: bool, errorMsg: error } };
}
export function formIsSubmitting(bool) {
  return { type: 'FORM_IS_SUBMITTING', isSubmitting: bool };
}
export function uploadedId(id) {
  return { type: 'SET_UPLOADED_ID', uploadedId: id };
}
export function uploadProgress(progress) {
  return { type: 'UPLOAD_PROGRESS', uploadProgress: progress };
}
export function newItem(newItem) {
  return { type: 'DASHBOARD_SUBMIT_UPLOAD_SUCCESS', newItem };
}
export function dashboardSnack(text) {
  console.log('dashboardsnack', text);
  return {
    type: 'DASHBOARD_SNACK',
    dashboardSnack: text,
  };
}

export function clearUploadForm(bool) {
  return (dispatch) => {
    // clear everything
    if (bool == true) {
      dispatch(newItem([]));
      dispatch(uploadedId(null));
    }
    // just clear the form itself
    dispatch(uploadProgress(null));
    dispatch(formIsSubmitting(false));
    dispatch(dashboardUploadHasErrored(false, null));
  };
}

export function deleteItem(id, fileKey, thumbKey, thumbs) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const {
      auth: { jwToken },
    } = getState().root;
    if (jwToken) {
      fetch('/api/deleteFile', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: `bearer ${jwToken}` },
        body: JSON.stringify({
          id,
          fileKey,
          thumbKey,
          thumbs,
        }),
      })
        .catch((error) => {
          console.error(error);
          reject('Error deleteing PDF');
          dispatch(dashboardSnack('Delete failed 😤'));
        })
        .then(res => res.json())
        .then((res) => {
          console.log('res', res);
          if (res.error) {
            console.error(res.error);
            dispatch(dashboardSnack('Delete failed 😤'));

            reject('Error deleteing PDF');
          }
          if (res.data.data.deletePdfById.pdf.id) {
            console.log('deleted', res.data.data.deletePdfById.pdf.id);
            resolve(res.data.data.deletePdfById.pdf.id);
            dispatch(dashboardSnack('Deleted ✅'));
          }
        });
    } else {
      reject('unauthorized');
    }
  });
}

// export function getShortLink(downloadURL) {
//   return dispatch => {
//     return new Promise((resolve, reject) => {
//       //create a promise so we can chain the function with .then
//       console.log(downloadURL);
//       let longLink = encodeURIComponent(downloadURL);
//       console.log('encoded', longLink);
//       fetch(
//         'https://api-ssl.bitly.com/v3/shorten?login=ajndev&apikey=R_a915191ac5204367ab0db18b75a2d0f0&longUrl=' +
//           longLink
//       )
//         .then(response => {
//           console.log(response);

//           if (!response.ok) {
//             dispatch(dashboardUploadHasErrored(true, response.statusText));

//             throw Error(response.statusText);
//           }
//           // console.log(response)
//           return response.json();
//         })
//         .then(text => {
//           console.log('data: ', text.data);
//           if (text.data.url == null) {
//             resolve(downloadURL);
//           } else {
//             resolve(text.data.url);
//           }
//         })
//         .catch(ex => {
//           console.log('parsing failed', ex);
//           throw 'error';
//           reject(Error(ex));
//           dispatch(dashboardUploadHasErrored(true, ex));
//         });
//     });
//   };
// }
