import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  root: {
    backgroundBlendMode: 'luminosity',
    backgroundColor: theme.palette.primary.dark,
    backgroundRepeat: 'no-repeat',
    // backgroundSize: 'contain',
    backgroundPosition: 'calc(100% - 48px)',
    backgroundSize: '100px',
    border: `16px solid ${theme.palette.primary.dark}`,
    [theme.breakpoints.down('xs')]: {
      border: `24px solid${theme.palette.primary.dark}`,
      backgroundBlendMode: 'overlay',
      backgroundPosition: 'right',
    },
  },
  container: {
    padding: 24,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  heading: {
    marginBottom: 15,
  },
  primary: {
    color: theme.palette.primary.light,
  },
});

const Header = ({ title, description, classes, style }) => (
  <div className={classes.root} style={style}>
    <div className={classes.container}>
      <Typography
        variant="display1"
        color="primary"
        classes={{ colorPrimary: classes.primary }}
        className={classes.heading}
      >
        {title}
      </Typography>
      <Typography
        variant="subheading"
        color="primary"
        classes={{ colorPrimary: classes.primary }}
      >
        {description}
      </Typography>
    </div>
  </div>
);

export default withStyles(styles)(Header);
