import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import InfoBox from './InfoBox';
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100vw',
    //backgroundColor: theme.palette.secondary.light,
    // backgroundColor: '#e8e9ec'
  },
  rootNavbarMargin: {
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
  },
  container: {
    margin: '0 auto',
    maxWidth: 1200,
  },
  row: {
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      margin: 24,
    },
  },
  // paper: {
  //   [theme.breakpoints.up('sm')]: {
  //     padding: 24,
  //   },
  //   color: theme.palette.text.secondary,
  // },
});

const Container = ({ classes, children, noMargin }) => (
  <div
    className={classnames(classes.root, !noMargin && classes.rootNavbarMargin)}
  >
    <noscript>
      <InfoBox>Please enable javascript to use this application.</InfoBox>
    </noscript>
    {children}
  </div>
);
Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Container);
