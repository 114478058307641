import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loggedIn } from '../actions/auth';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Content from './Content';
import Header from './Header';

export default function(ComposedComponent) {
  class Authentication extends Component {
    constructor(props) {
      super();
      this.state = {
        loggedIn: props.token ? true : false,
      };
    }
    componentDidMount() {
      // if(!this.props.authenticate
      //   this.context.router.push('/login');
      //   console.error('You need to be logged to access this page');
      // }
      // check the token before we can proceed
      this.props
        .loggedIn()
        .then((res) => {
          this.setState({ loggedIn: true });
          console.log('loggedin ✅');
          if (res === 'refreshed') {
            // we got a new cookie; refresh the page in case of side effects.
            window.location.reload();
          }
        })
        .catch((err) => {
          this.setState({ loggedIn: false });
          console.log(err);
          console.error('You need to be logged in to access this page');
          //  this.props.pushRoute('/login');
        });
    }
    componentWillUpdate(nextProps) {
      console.log('requireAuth willUpdate');
      if (!nextProps.authenticated) {
        this.props.history.push('/login');
        console.error('You need to be logged in to access this page');
      }
    }
    render() {
      console.log('this.state.loggedIn', this.state.loggedIn);
      return this.state.loggedIn ? (
        <ComposedComponent {...this.props} />
      ) : (
        <div>
          <Header title="Hmmm" description="Nothing to see here." />
          <Content>
            <Typography>
              Please <Link to="/login">login</Link> to view this page.
            </Typography>
          </Content>
        </div>
      );
    }
  }
  Authentication.contextTypes = {
    router: PropTypes.object.isRequired,
  };
  Authentication.propTypes = {
    authenticated: PropTypes.bool,
  };
  const mapStateToProps = (state) => ({
    authenticated: state.root.auth.isLogged,
    token: state.root.auth.jwToken,
  });
  const mapDispatchToProps = (dispatch) => ({
    loggedIn: () => dispatch(loggedIn()),
  });
  return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}
