import React from 'react';
const Wrapper = ({style, className, children}) => {
  return (
    <div className={className} style={{ ...styles.wrapper, ...style }}>{children}</div>
  )
}

const styles = {
	wrapper: {
	  padding: 24,
	  background: 'white',
	  marginBottom: 24,
	}
}
export default Wrapper;