import gql from 'graphql-tag';

//client queries / mutations
export const dashboardTab = gql`
  query dashboardTab {
    dashboardTab @client
  }
`;
export const sortBy = gql`
  query sortBy {
    sortBy @client
  }
`;

export const currentView = gql`
  query currentView {
    currentView @client
  }
`;

// export const toggleView = gql`
//   mutation toggleView($view: String!) {
//     toggleView(view: $view) @client
//   }
// `;

//todo: remember last page of (last?) pdf
export const currentPage = gql`
  query currentPage {
    currentPage @client {
      page
      pdfId
    }
  }
`;

//server queries / mutations
export const deletePerson = gql`
  mutation deletePerson($id: Int!) {
    deletePersonById(input: { id: $id }) {
      deletedPersonId
    }
  }
`;

export const updateRole = gql`
  mutation updatePersonRole($id: Int!, $role: String!) {
    updatePersonRole(input: { id: $id, role: $role }) {
      person {
        id
      }
    }
  }
`;

export const revokeRefreshTokens = gql`
  mutation revokeRefreshTokens($id: Int!) {
    revokeRefreshTokens(input: { id: $id }) {
      bigInt
    }
  }
`;

export const refreshAccessToken = gql`
  mutation refreshAccessToken($id: Int!, $refreshToken: String!) {
    refreshAccessToken(input: { id: $id, refreshToken: $refreshToken }) {
      jwtToken
    }
  }
`;
export const allPeople = gql`
  query allPeople {
    allPeople {
      nodes {
        id
        firstName
        lastName
        createdAt
        pdfsByAuthorId {
          nodes {
            id
          }
        }
      }
    }
  }
`;
export const roleById = gql`
  query roleById($id: Int!) {
    roleById(id: $id)
  }
`;
export const authenticate = gql`
  mutation authenticate($email: String!, $password: String!) {
    authenticate(input: { email: $email, password: $password }) {
      tokenResults {
        jwtToken
        refreshToken
      }
      query {
        currentPerson {
          id
        }
      }
    }
  }
`;

// hands up if you wish you could work out how to
// export/import gql queries with fragments 🙌
export const allPdfs = gql`
  query allPdfs(
    $sortBy: [PdfsOrderBy!]
    $condition: PdfCondition!
    $after: Cursor
    $before: Cursor
    $first: Int
    $last: Int
  ) {
    allPdfs(
      orderBy: $sortBy
      condition: $condition
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pdfType
          edition
          fileKey
          fileUrl
          thumbKey
          thumbUrl
          createdAt
          updatedAt
          slug
          datePublished
          yearPublished
          monthPublished
          publication
          authorId
          size
          shortLink
          thumbs
          pdfInfoById {
            status
            id
          }
        }
      }
    }
  }
`;
export const searchPdfs = gql`
  query searchPdfs($search: String!, $after: Cursor) {
    searchPdfs(search: $search, after: $after, first: 12) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pdfType
          edition
          fileKey
          fileUrl
          thumbKey
          thumbUrl
          createdAt
          updatedAt
          slug
          datePublished
          yearPublished
          monthPublished
          publication
          authorId
          size
          shortLink
        }
      }
    }
  }
`;

export const allPublicationsWithPdfs = gql`
  query allPublicationsWithPdfs(
    $sortBy: [PdfsOrderBy!]
    $condition: PdfCondition!
    $pubCondition: PublicationCondition!
    $after: Cursor
    $before: Cursor
    $first: Int
    $last: Int
  ) {
    allPublications(condition: $pubCondition, orderBy: PUBLICATION_ASC) {
      nodes {
        publication
        pdfsByPublication(
          orderBy: $sortBy
          condition: $condition
          after: $after
          before: $before
          first: $first
          last: $last
        ) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pdfType
              edition
              fileKey
              fileUrl
              thumbKey
              thumbUrl
              createdAt
              updatedAt
              slug
              datePublished
              yearPublished
              monthPublished
              publication
              authorId
              size
              shortLink
            }
          }
        }
      }
    }
  }
`;

export const allPublications = gql`
  query allPublications($condition: PdfCondition) {
    allPublications(orderBy: PUBLICATION_ASC) {
      edges {
        node {
          id
          publication
          pdfsByPublication(condition: $condition) {
            totalCount
          }
        }
      }
    }
  }
`;

export const allTypes = gql`
  query {
    __type(name: "PdfType") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const allEditions = gql`
  query allEditions {
    allEditions(orderBy: EDITION_ASC) {
      edges {
        node {
          id
          edition
        }
      }
    }
  }
`;

export const deletePdf = gql`
  mutation deletePdf($id: Int!) {
    deletePdfById(input: { id: $id }) {
      pdf {
        id
      }
    }
  }
`;

// this is used on the server via performQuery
// so no gql is required.
export const createPdf = gql`
  mutation createPdf(
    $authorId: Int!
    $fileUrl: String
    $fileKey: String
    $thumbUrl: String
    $thumbKey: String
    $size: Int
    $datePublished: Date
    $publication: String!
    $pdfType: PdfType
    $edition: String!
    $thumbs: JSON
  ) {
    createPdf(
      input: {
        pdf: {
          authorId: $authorId
          fileUrl: $fileUrl
          fileKey: $fileKey
          thumbUrl: $thumbUrl
          thumbKey: $thumbKey
          size: $size
          datePublished: $datePublished
          publication: $publication
          pdfType: $pdfType
          edition: $edition
          thumbs: $thumbs
        }
      }
    ) {
      pdf {
        id
        slug
        pdfType
        datePublished
        createdAt
        thumbUrl
        fileKey
        fileUrl
        edition
        publication
        yearPublished
        monthPublished
        thumbs
      }
    }
  }
`;
export const updatePdfStatus = gql`
  mutation updatePdfStatus($id: Int!, $status: PdfStatus!) {
    updatePdfInfoById(input: { id: $id, pdfInfoPatch: { status: $status } }) {
      pdfInfo {
        id
        status
      }
    }
  }
`;
export const pdfInfoById = gql`
  query pdfInfoById($id: Int!) {
    pdfInfoById(id: $id) {
      id
      status
    }
  }
`;
export const updatePdf = gql`
  mutation updatePdf($id: Int!, $pdfPatch: PdfPatch!) {
    updatePdfById(input: { id: $id, pdfPatch: $pdfPatch }) {
      pdf {
        id
        shortLink
        slug
        pdfType
        datePublished
        createdAt
        thumbUrl
        fileUrl
        fileKey
        edition
        publication
        yearPublished
        monthPublished
        thumbs
      }
    }
  }
`;

export const pdfBySlug = gql`
  query pdfBySlug($slug: String!) {
    pdfBySlug(slug: $slug) {
      edition
      datePublished
      publication
      yearPublished
      monthPublished
      slug
      fileKey
      fileUrl
      id
      shortLink
      thumbs
      pdfInfoById {
        status
        id
      }
    }
  }
`;
export const pdfById = gql`
  query pdfById($id: Int!) {
    pdfById(id: $id) {
      id
      pdfType
      edition
      fileKey
      fileUrl
      thumbKey
      thumbUrl
      createdAt
      slug
      datePublished
      yearPublished
      monthPublished
      publication
      authorId
      size
      shortLink
      thumbs
      pdfInfoById {
        status
        id
      }
    }
  }
`;

export const createPublication = gql`
  mutation createPublication($publication: String!) {
    createPublication(input: { publication: { publication: $publication } }) {
      publication {
        publication
        id
      }
    }
  }
`;

export const editPublication = gql`
  mutation editPublication($publication: String!, $id: Int!) {
    updatePublicationById(
      input: { id: $id, publicationPatch: { publication: $publication } }
    ) {
      publication {
        publication
        id
      }
    }
  }
`;
export const deletePublication = gql`
  mutation deletePublication($id: Int!) {
    deletePublicationById(input: { id: $id }) {
      publication {
        publication
        id
      }
    }
  }
`;
// account
export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($id: Int!, $firstName: String, $lastName: String) {
    updatePersonById(
      input: {
        id: $id
        personPatch: { firstName: $firstName, lastName: $lastName }
      }
    ) {
      person {
        id
        firstName
        lastName
      }
    }
  }
`;
export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updateUserPassword(
    $id: Int!
    $currentPassword: String!
    $newPassword: String!
  ) {
    resetPersonPassword(
      input: { id: $id, password: $currentPassword, newPassword: $newPassword }
    ) {
      string
    }
  }
`;

export const personById = gql`
  query personById($id: Int!) {
    personById(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

export const currentPerson = gql`
  query currentPerson {
    currentPerson {
      id
      firstName
      lastName
    }
  }
`;
export const currentRole = gql`
  query currentRole {
    currentRole
  }
`;

export const registerPerson = gql`
  mutation registerPerson(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $personRole: String!
  ) {
    registerPerson(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
        personRole: $personRole
      }
    ) {
      personEdge {
        node {
          id
          fullName
        }
      }
    }
  }
`;

export const allErrorLogs = gql`
  query allErrorLogs(
    $orderBy: [ErrorLogsOrderBy!]
    $after: Cursor
    $before: Cursor
    $first: Int
    $last: Int
  ) {
    allErrorLogs(
      orderBy: $orderBy
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          message
          stack
          status
          createdAt
        }
      }
    }
  }
`;
