import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
  root: {
    // backgroundColor: '#ededed',
    minHeight: '50vh',
  },
  container: {
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      padding: 24,
    },
  },
  heading: {
    marginBottom: 15,
  },
});

const Content = ({ children, classes, style, containerStyle }) => {
  return (
    <div className={classes.root} style={style}>
      <div className={classes.container} style={containerStyle}>
        {children}
      </div>
    </div>
  );
};

export default withStyles(styles)(Content);
