import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';

import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import WithAdmin from './WithAdmin';
import { logout } from '../actions/auth';
import { MenuButton } from './MenuButton';
import { MenuListItem } from './MenuListItem';

const drawerWidth = 240;

const menuItems = [
  {
    label: 'Pdfs',
    icon: 'list',
    path: '/pdfs/supplement/all/all',
    match: '/pdfs/:type/:publication/:edition',
    roles: [
      'ajn_pdfs_admin',
      'ajn_pdfs_editor',
      'ajn_pdfs_author',
      'ajn_pdfs_user',
    ],
  },
  {
    label: 'Dashboard',
    icon: 'cloud_upload',
    path: '/dashboard',
    match: '/dashboard',
    roles: ['ajn_pdfs_admin', 'ajn_pdfs_editor', 'ajn_pdfs_author'],
  },
  {
    label: 'Accounts',
    icon: 'settings',
    path: '/account',
    match: '/account',
    roles: ['ajn_pdfs_admin'],
  },
];

const styles = (theme) => ({
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  logo: {
    maxWidth: '114px',
    height: '34px',
    width: '100%',
    backgroundImage: `url(${require('../img/ajn-pdfs.svg')})`,
    backgroundSize: 'contain',
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
  },
  logoContainer: {
    flex: 1,
  },
  appBar: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hidden: {
    display: 'none',
  },
  drawerPaper: {
    position: 'absolute',
    height: '100vh',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: 0,
    border: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
  },
  drawerInnerClosed: {
    // safari fix
    width: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 16,
  },
  overlay: {
    zIndex: -1,
    position: 'absolute',
    top: 0,
    opacity: 0,
    left: 0,
    right: 0,
    // width: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    overflowX: 'hidden',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  overlayActive: {
    zIndex: 999,
    left: 0,
    width: '100%',
    opacity: 1,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }

  handleDrawerToggle = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  logout = () => {
    this.props.logout();
    this.handleDrawerClose();
  };

  render() {
    const { classes, isLogged, logout, match, location } = this.props;

    // console.log('navbar match', isLogged, match, location);
    return match.params.path === '/view/:type/:slug' ? null : (
      <div className="nav-bar">
        <React.Fragment>
          <AppBar
            elevation={0}
            position="static"
            className={classNames(
              classes.appBar,
              this.state.drawerOpen && classes.appBarShift
            )}
          >
            <Toolbar className="title-bar">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
                className={classNames(
                  classes.menuButton,
                  this.state.drawerOpen && classes.hide
                )}
              >
                <Icon>menu</Icon>
              </IconButton>
              <div className={classes.logoContainer}>
                <Link
                  to={isLogged ? '/pdfs/supplement/all/all' : '/'}
                  style={{ display: 'flex', width: 114 }}
                >
                  <div
                    className={classes.logo}
                    onClick={this.handleDrawerClose}
                    alt="AJN"
                  />
                </Link>
              </div>
              {isLogged && (
                <WithAdmin refetch={isLogged}>
                  {(isAdmin, role) => (
                    <Hidden xsDown>
                      {menuItems.map((item) => (
                        <MenuButton
                          key={item.label}
                          {...item}
                          role={role}
                          matchPath={match.params.path}
                          onClick={this.handleDrawerClose}
                        />
                      ))}
                    </Hidden>
                  )}
                </WithAdmin>
              )}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.drawerOpen && classes.drawerPaperClose
              ),
            }}
            open={this.state.drawerOpen}
          >
            <div
              className={
                this.state.drawerOpen
                  ? classes.drawerInner
                  : classes.drawerInnerClosed
              }
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={this.handleDrawerClose}>
                  <Icon>keyboard_arrow_left</Icon>
                </IconButton>
              </div>
              <Divider />
              {isLogged && (
                <WithAdmin refetch={isLogged}>
                  {(isAdmin, role) => (
                    <List className={classes.list}>
                      {menuItems.map((item) => (
                        <MenuListItem
                          key={item.label}
                          {...item}
                          role={role}
                          matchPath={match.params.path}
                          onClick={this.handleDrawerClose}
                        />
                      ))}

                      <Divider />
                    </List>
                  )}
                </WithAdmin>
              )}
              <List>
                {/* logout/login */}
                {isLogged ? (
                  <MenuListItem
                    onClick={this.logout}
                    role="all"
                    roles={['all']}
                    label="Logout"
                    icon="exit_to_app"
                  />
                ) : (
                  <MenuListItem
                    path="/login"
                    role="all"
                    roles={['all']}
                    onClick={this.handleDrawerClose}
                    label="Login"
                    icon="person"
                  />
                )}
              </List>

              <Divider />
            </div>
          </Drawer>
          <div
            className={classNames(
              classes.overlay,
              this.state.drawerOpen && classes.overlayActive
            )}
            onClick={this.handleDrawerClose}
          />
        </React.Fragment>
      </div>
    );
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  isLogged: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  initialized: state.root.auth.initialized,
  isLogged: state.root.auth.isLogged,
  isAdmin: state.root.user.isAdmin,
});
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withRouter(Navbar)));
