import React from 'react';
import loadable from '@loadable/component';
import Loading from '../components/Loading';

// const RouteComponent = ({ component, loading, error, ownProps }) => {
//   const Component = component;
//   error && <InfoBox type="error">This shouldn't happen.</InfoBox>;
//   loading && <Loading />;
//   return isClient ? (
//     <AnimatedComponent
//       timeout={animationTimeout}
//       transitionStyles={transitionStyles}
//       defaultStyle={defaultStyle}
//     >
//       <Component {...ownProps} />
//     </AnimatedComponent>
//   ) : (
//     <Component {...ownProps} />
//   );
// };

export const Home = loadable(
  () => import(/* webpackChunkName: "Home" */ '../views/Home'),
  {
    LoadingComponent: Loading,
    // render({ Component, error, loading, ownProps }) {
    //           error && ( <InfoBox type="error">This shouldn't happen.</InfoBox> )
    //     loading && ( <Loading/> )
    //   return (
    //     <AnimatedComponent timeout={animationTimeout} transitionStyles={transitionStyles} defaultStyle={defaultStyle}>
    //       <Component {...ownProps}/>
    //     </AnimatedComponent>
    //   );
    // }
  }
);
export const Login = loadable(
  () => import(/* webpackChunkName: "Login" */ '../views/Login'),
  {
    LoadingComponent: Loading,
  }
);

export const List = loadable(
  () => import(/* webpackChunkName: "List" */ '../views/List/List'),
  {
    LoadingComponent: Loading,
  }
);

export const Dashboard = loadable(
  () =>
    import(
      /* webpackChunkName: "Dashboard" */ '../views/protected/Dashboard/Dashboard'
    ),
  {
    LoadingComponent: Loading,
  }
);

export const Edit = loadable(
  () => import(/* webpackChunkName: "Edit" */ '../views/protected/Edit'),
  {
    LoadingComponent: Loading,
  }
);

export const Account = loadable(
  () =>
    import(
      /* webpackChunkName: "Account" */ '../views/protected/Account/Account'
    ),
  {
    LoadingComponent: Loading,
  }
);

export const PdfView = loadable(
  () => import(/* webpackChunkName: "PdfView" */ '../views/PdfView/PdfView'),
  {
    LoadingComponent: Loading,
  }
);

export const ResetPass = loadable(
  () => import(/* webpackChunkName: "ResetPass" */ '../views/ResetPass'),
  {
    LoadingComponent: Loading,
  }
);
