import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import placeholder from '../img/pdf-placeholder.png';
import getViewPdfLink from '../helpers/getViewPdfLink';
import config from '../config/config';

import AnimatedComponent from './AnimatedComponent';

const placeholderPdf = {
  createdAt: '2018-04-16T07:03:16.242318',
  datePublished: '2018-04-12',
  edition: 'Edition',
  id: 0,
  monthPublished: 'Month',
  publication: 'Publication',
  slug: '',
  thumbUrl: placeholder,
  yearPublished: 'Year',
};

const PdfPreview = ({ item, classes, thumb }) => {
  console.log(item);
  const pdf = item;

  return (
    <AnimatedComponent
      in
      transitionStyles={wrapTransition}
      defaultStyle={wrapDefaultStyle}
    >
      <Preview classes={classes} pdf={pdf || placeholderPdf} thumb={thumb} />
    </AnimatedComponent>
  );
};

class Preview extends Component {
  getLink = () => {
    let link;
    if (pdf.pdfType) {
      if (pdf.shortLink) {
        link = `${config.shortLinkDomain}${pdf.shortLink}`;
      } else {
        link = getViewPdfLink(pdf.pdfType, pdf.slug);
      }
    } else {
      link = '#';
    }
    console.log('pdf preview link', link);
    return link;
  };

  render() {
    const { pdf, thumb, classes, in: inProp } = this.props;
    return (
      <a
        className={classNames(classes.wrap, pdf.pdfType && classes.wrapIn)}
        href={pdf.pdfType ? this.getLink : '#'}
      >
        <div className={classes.thumb}>
          {thumb || (
            <img
              style={{ width: '100%' }}
              src={pdf.thumbUrl}
              alt="pdf cover preview"
            />
          )}
        </div>
        <Typography variant="title">
          {pdf.publication || 'no title '}{' '}
        </Typography>
        <Typography variant="body1">{pdf.edition || ''}</Typography>
        <Typography>{`${pdf.monthPublished} ${pdf.yearPublished}`}</Typography>
      </a>
    );
  }
}

const wrapTransition = {
  entering: { opacity: 0, transform: `scale(${0.75})` },
  entered: { opacity: 1, transform: `scale(${1})` },
  exiting: { opacity: 1, transform: `scale(${1})` },
  exited: { opacity: 0, transform: `scale(${0.75})` },
};
const wrapDefaultStyle = {
  transition: 'all 0.7s cubic-bezier(1, -0.12, 0.51, 1.24)',
};
const styles = (theme) => ({
  wrap: {
    display: 'block',
    opacity: 0.5,
    color: '#c2c2c2',
    maxWidth: 220,
    // transition: 'all 0.7s cubic-bezier(1, -0.12, 0.51, 1.24)',
    // transform:`scale(${0.75})`,
  },
  wrapIn: {
    opacity: 1,
    // transform:`scale(${1})`,
  },
  thumb: {
    marginBottom: 8,
    maxWidth: 220,
    width: '100%',
    // border: '1px solid #adadad',
    transition: 'all 0.7s cubic-bezier(1, -0.12, 0.51, 1.24)',
  },
  datePublished: {
    display: 'inline',
  },
});

PdfPreview.propTypes = {
  pdf: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(PdfPreview);
