import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import Typography from '@material-ui/core/Typography';
import { currentRole } from '../queries';
import { Loading } from '../components';
import { useQuery } from '@apollo/client';
import InfoBox from './InfoBox';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const WithAdmin = ({ refetch, children }) => {
  // async componentWillReceiveProps(nextProps) {
  //   if (
  //     this.props.refetch !== nextProps.refetch &&
  //     nextProps.refetch === true
  //   ) {
  //   }
  //   // {
  //   //   const role = await this.props.client.queryManager.refetchQueryByName(
  //   //     'currentRole'
  //   //   );
  //   // }
  // }
  // shouldComponentUpdate(nextProps) {
  // 	if (this.props.refetch !== nextProps.refetch) return true;
  // 	return false;
  // }
  // render() {
  const { loading, error, data, refetch: refetchFn } = useQuery(currentRole, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (refetch) {
      console.log('refetch currentRole');
      refetchFn();
    }
  }, [refetch]);
  useEffect(() => {
    if (refetchFn) {
      console.log('refetch2 currentRole');
      refetchFn();
    }
  }, []);
  if (error)
    return (
      <InfoBox type="error">
        There was an authentication error{' '}
        <Button
          style={{ marginLeft: 12 }}
          size="small"
          component={Link}
          to="/login"
        >
          Login
        </Button>
      </InfoBox>
    );
  if (loading) return '...';
  return data && data.currentRole === 'ajn_pdfs_admin'
    ? children(true, data.currentRole)
    : children(false, data.currentRole);
};

WithAdmin.propTypes = {
  children: PropTypes.func.isRequired,
};

export default WithAdmin;
