import { ApolloClient, InMemoryCache } from '@apollo/client';

// import { setContext } from '@apollo/client/link/context';
import loadable from '@loadable/component';

// import { CachePersistor } from 'apollo3-cache-persist';
// const { CachePersistor } = require('apollo3-cache-persist/lib/bundle.umd.js');
// console.log(CachePersistor);
import { createHttpLink } from '@apollo/client/link/http';
import { resolvers, defaults, policies } from './resolvers';

const isClient = typeof window !== 'undefined';

// const jwToken = getToken();
// console.log(jwToken);

// const httpLink = createHttpLink({
//   uri: `${process.env.APP_DOMAIN}/graphql`,
//   credentials: 'same-origin'
//   // headers: {
//   //   Authorization: `Bearer ${jwToken}` || null,
//   // },
// });

// const middlewareLink = setContext((_, { headers }) => ({
//   headers: {
//     ...headers,
//     authorization: jwToken ? `Bearer ${jwtToken}` : null,
//   }
// }));
// const link = middlewareLink.concat(httpLink);

// const apolloCache = new InMemoryCache().restore(
//   isClient && window.__APOLLO_STATE__,
// );

// export const apolloPersistor = new CachePersistor({
//   cache: apolloCache,
//   debug: true,
//   storage: window.localStorage,
// });
// apolloPersistor.persist().then(stuff => console.log('stuff', stuff))
// apolloPersistor.restore().then(stuff => console.log('stuff', stuff));
// apolloPersistor.getLogs(true);

// const stateLink = withClientState({
//   cache: apolloCache,
//   resolvers,
//   defaults,
// });

// export const client = new ApolloClient({
//   // ssrMode: true,
//   ssrForceFetchDelay: 1000,
//   connectToDevTools: true,
//   queryDeduplication: true,
//   // Remember that this is the interface the SSR server will use to connect to the
//   // API server, so we need to ensure it isn't firewalled, etc

//   // < apollo-client 2.5 client state
//   // link: ApolloLink.from([
//   //   stateLink,
//   //   httpLink
//   // ]),
//   cache: apolloCache,
//   link: httpLink,
//   resolvers,
// });
const setupCachePersistor = async (apolloCache) => {
  return new Promise(async (resolve, reject) => {
    if (isClient) {
      const persistCache = import('apollo3-cache-persist/lib/persistCache');
      const CP = await persistCache;
      console.log(CP, persistCache);

      const persistor = new CP.default({
        cache: apolloCache,
        storage: isClient && window.localStorage,
        maxSize: false, // set to unlimited (default is 1MB https://github.com/apollographql/apollo-cache-persist)
        debug: true, // enables console logging
      });
      console.log('persistor', persistor);
      return resolve(persistor);
    } else {
      return resolve();
    }
  });
};

const setupApollo = async () => {
  const apolloCache = new InMemoryCache({ typePolicies: policies }).restore(
    isClient && window.__APOLLO_STATE__
  );
  const httpLink = createHttpLink({
    uri: `${process.env.APP_DOMAIN}/graphql`,
    credentials: 'same-origin',
  });

  const persistor = await setupCachePersistor(apolloCache);

  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    ssrForceFetchDelay: 200,
    connectToDevTools: true,
    queryDeduplication: true,
    // uri: `${process.env.APP_DOMAIN}/graphql`,
    // credentials: 'same-origin',
    cache: apolloCache,
    link: httpLink,
    // resolvers,
  });
  // console.log(persistor);
  client.onResetStore(() => {
    console.log('reset store after logout');
    // apolloCache.writeData({ data: { ...defaults } });
    // persistor.purge();
    // persistor.remove();
  });

  // apolloCache.writeData({
  //   data: {
  //     ...defaults,
  //   },
  // });
  // const persistor = await setupCachePersistor(apolloCache);

  // await persistor.restore();
  // await persistor.persist();
  // await persistCache({
  //   cache: apolloCache,
  //   storage: isClient && window.localStorage,
  //   maxSize: false, // set to unlimited (default is 1MB https://github.com/apollographql/apollo-cache-persist)
  //   debug: true, // enables console logging
  // })

  return {
    client,
    // persistor,
  };
};

export default setupApollo;
