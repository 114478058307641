import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

export const MenuButton = ({
  path,
  label,
  icon,
  onClick,
  match,
  role,
  roles,
  matchPath,
}) =>
  roles.includes(role) ? (
    <Tooltip
      id={`MenuButton-${label}-tooltip`}
      title={label}
      placement="bottom"
    >
      <IconButton
        component={path && Link}
        to={path}
        onClick={onClick}
        aria-label={label}
        style={{ color: '#ffffff' }}
      >
        <Icon>{icon}</Icon>
      </IconButton>
    </Tooltip>
  ) : null;
