import { combineReducers } from 'redux';
import { items, itemsHasErrored, itemsIsLoading, itemsCopiedLink, itemsSortBy, itemsFilterBy, itemsToggleView } from './items';
// import { newItem, dashboardUploadHasErrored, formIsSubmitting, uploadProgress, uploadedId, clearUploadForm } from './dashboard';
import { dashboardSnack } from './dashboard';
import routesPermissions from './routesPermissions';

import { routerReducer } from 'react-router-redux';
import auth from './auth';
import user from './user';

export default combineReducers({
  routing: routerReducer,
  routesPermissions,
  items,
  dashboardSnack,
  auth,
  user,
//   itemsHasErrored,
//   itemsIsLoading,
//   itemsCopiedLink,
//   itemsSortBy,
//   itemsFilterBy,
//   itemsToggleView,
});