import { makeVar } from '@apollo/client';
import {
  relayStylePagination,
  concatPagination,
} from '@apollo/client/utilities';

export const resolvers = {
  // Mutation: {
  //   updateNetworkStatus: (_, { isConnected }, { cache }) => {
  //     const data = {
  //       networkStatus: {
  //         __typename: 'NetworkStatus',
  //         isConnected,
  //       },
  //     };
  //     cache.writeQuer({ data });
  //     return null;
  //   },
  // },
  Mutation: {
    toggleView: (_, { view }, { cache }) => {
      console.log('mutation toggleview', view);
      const data = {
        currentView: {
          __typename: 'currentView',
          view,
        },
      };
      cache.writeQuery({
        query: gql`
          mutation toggleView($view: String!) {
            toggleView(view: $view) @client
          }
        `,
        data,
      });
      return null;
    },
  },
};
export const defaults = {
  dashboardTab: 'Upload',
  sortBy: 'DATE_PUBLISHED_DESC',
  currentView: 'List',
};

export const currentViewVar = makeVar(defaults.currentView);
export const dashboardTabVar = makeVar(defaults.dashboardTab);
export const sortByVar = makeVar(defaults.sortBy);

const fixedRelayStylePagination = (...args) => ({
  ...relayStylePagination(...args),
  read: (...readArgs) => {
    const existing = readArgs[0];
    const originalRead = relayStylePagination(...args).read;

    if (!existing || !originalRead) {
      return;
    }
    return originalRead(...readArgs);
  },
});

export const policies = {
  Query: {
    fields: {
      // searchPdfs: fixedRelayStylePagination(['search']),
      allPdfs: fixedRelayStylePagination(['condition', 'orderBy']),

      currentView: {
        read() {
          return currentViewVar();
        },
      },
      sortBy: {
        read() {
          return sortByVar();
        },
      },
      dashboardTab: {
        read() {
          return dashboardTabVar();
        },
      },
    },
  },
};
