import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

const duration = 300;

const defaultDefaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const defaultTransitionStyles = {
    entering: { opacity: 0 },
    entered:  { opacity: 1 },
    exiting:{ opacity: 1 },
    exited: { opacity: 0 },
};

const Animation = ({ in: inProp, isServer, children, transitionStyles = defaultTransitionStyles, defaultStyle = defaultDefaultStyle, timeout = duration}) => {
  const isClient = typeof window !== 'undefined'; 

  return (
    isClient && !isServer ? 
    <Transition in={inProp} timeout={timeout} mountOnEnter unMountOnExit>
      {(state) => {
        return (
          <div style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}>
                
          {children}
          </div>
        )
      }}
    </Transition>
    :
      children
    
  );
}


class AnimatedComponent extends PureComponent {
  state = {
    in: false,
  }
  componentDidMount() {
    console.log('AnimatedComponent: mount');
    this.setState({in: true});
  }
  componentWillUnmount() {
    console.log('AnimatedComponent: unmount');
    this.setState({in: false});
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.inProp !== this.props.inProp) {
      this.setState((state) => ({in: nextProps.inProp}));
    }
  }

  render() {
    const { in: inProp } = this.state;

    return (
      <Animation in={inProp} {...this.props}>
          {this.props.children}
      </Animation>
      )
  }
}


AnimatedComponent.propTypes = {
  children: PropTypes.object.isRequired,
  isServer: PropTypes.bool,
}
AnimatedComponent.defaultProps = {
  isSever: false
}

export default AnimatedComponent;