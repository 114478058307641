import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import blue from '@material-ui/core/colors/blue';

const headingFont =
  '"Archivo Black",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif';
const bodyFont = '"Muli","Helvetica Neue",Helvetica,Arial,sans-serif';

const primary = '#263238'; // #F44336

// Create a MUI theme instance.
let theme = createMuiTheme({
  palette: {
    primary: {
      light: blue[50],
      medium: blue[500],
      main: blue[700],
      dark: '#090e44',
    },
    blue: {
      medium: blue[700],
    },
    secondary: { light: indigo[200], main: indigo[400] },
    error: pink,
    // success: lime,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    MuiTab: {
      label: {
        fontSize: '0.96rem',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: blue[700],
        backgroundColor: 'white',
      },
    },
    MuiTableSortLabel: {
      active: {
        color: 'white',
        borderBottom: '1px solid',
      },
      root: {
        color: 'white',
        '&:hover, &:focus': {
          color: 'white',
        },
      },
    },
  },
  typography: {
    htmlFontSize: 12,
    // Use the system font.
    fontFamily: bodyFont,
    headline: {
      fontFamily: headingFont,
    },
    display1: {
      fontFamily: headingFont,
    },

    display3: {
      fontFamily: headingFont,
    },
    display4: {
      fontFamily: headingFont,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
});

// I'm sure there's a reason for doing this
// probably to access the theme properties like breakpoints
theme = {
  ...theme,
  typography: {
    ...theme.typography,
    display2: {
      ...theme.typography.display2,
      fontFamily: headingFont,
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.15rem',
      },
    },
    title: {
      ...theme.typography.title,
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.40rem',
      },
    },
    headline: {
      ...theme.typography.headline,
      fontSize: '1.75rem',
      fontWeight: 600,
      fontFamily: bodyFont,
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.40rem',
      },
    },
  },
};
export default theme;
