import initialState from './initialState';
//import {REHYDRATE} from 'redux-persist/constants'

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {

    // case REHYDRATE:
    //   const incoming = action.payload.auth
    //   if (incoming) return {...state, ...incoming}
    //   return state

    case 'AUTH_INITIALIZATION_DONE':
      return Object.assign({}, state, {
        initialized: true
      });

    case 'AUTH_LOGGED_IN_SUCCESS':
      return Object.assign({}, state, {
        isLogged: true,
        currentUserUID: action.userUID
      });

    case 'LOGIN_ERROR':
      return Object.assign({}, state, {
        loginErrorMessage: action.message
      });

    case 'AUTH_SET_TOKEN':
      return Object.assign({}, state, {
        jwToken: action.jwToken
      });
    case 'AUTH_SET_REFRESH_TOKEN':
      return Object.assign({}, state, {
        refreshToken: action.refreshToken
      });  
    case 'AUTH_LOGGED_OUT_SUCCESS':
      return Object.assign({}, state, {
        isLogged: false,
        currentUserUID: null,
        jwToken: null,
      });
    default:
      return state;
  }
}
